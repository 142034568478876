<template>
  <div class="ranking bg_color">
    <div class="title">
      <div class="ranIcon">
        <van-image
          width="3vw"
          height="3vw"
          :src="require('@/assets/images/ran.png')"
        />
      </div>
      <h2>学生成绩排名</h2>
    </div>
    <div class="ranType">
      <div @click="renType = 0" :class="renType == 0 ? 'current' : ''">
        正确率
      </div>
      <div @click="renType = 1" :class="renType == 1 ? 'current' : ''">
        做题数
      </div>
      <div @click="renType = 2" :class="renType == 2 ? 'current' : ''">
        平均用时
      </div>
    </div>

    <table border="0" class="studentRan">
      <thead>
        <tr>
          <td>排名</td>
          <td>姓名</td>
          <td>做题数</td>
          <td>正确率</td>
          <td>平均用时</td>
        </tr>
      </thead>

      <tr v-for="(item, index) in tableData" :key="item.ran">
        <td class="list">
          <div
            :class="
              item.ran == 1
                ? 'first'
                : item.ran == 2
                ? 'second'
                : item.ran == 3
                ? 'third'
                : ''
            "
          >
            {{ index + 1 }}
          </div>
        </td>
        <td>{{ item.studentName }}</td>
        <td>{{ item.dqNum }}</td>
        <td>{{ (+item.correctRate).toFixed(2) }}</td>
        <td>{{ (+item.avgTakeTime).toFixed(2) }}</td>
      </tr>
      <tr v-if="!tableData.length">
        <td colspan="5">
          <van-empty
            description="暂无数据"
            image-size="80"
            :image="require('@/assets/images/kong.png')"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script setup>
import { ref, defineProps, watch, defineEmits } from "vue";

defineProps(["tableData"]);
const emit = defineEmits(["changeRanType"]);

let renType = ref(0);
watch(
  () => renType.value,
  () => {
    if (renType.value == 0) {
      emit("changeRanType", "correctRate");
    }
    if (renType.value == 1) {
      emit("changeRanType", "dqNum");
    }
    if (renType.value == 2) {
      emit("changeRanType", "avgTakeTime");
    }
  }
);
</script>

<style lang="less" scoped>
@import "@/common/style/bg_color.less";
.title {
  border-radius: 10px 10px 0 0;
  background-image: linear-gradient(
    90deg,
    rgb(100, 129, 234),
    rgb(53, 81, 182)
  );
  padding: 10px;
  text-align: center;
  position: relative;
  .ranIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  h2 {
    color: #fff;
  }
}
.ranType {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  div:nth-child(1) {
    border-radius: 20px 0 0 20px;
  }
  div:nth-child(2) {
    border-left: none;
    border-right: none;
  }
  div:nth-child(3) {
    border-radius: 0 20px 20px 0;
  }
  div {
    margin: 0;
    border: 1px solid #18a4e0;
    width: 50%;
    text-align: center;
  }
  .current {
    background-color: rgb(101, 130, 235);
    border: none;
    color: #fff;
  }
}

.studentRan {
  width: 100%;
  text-align: center;
  font-size: 11px;
  border-collapse: collapse;
  thead {
    tr {
      font-weight: 600;
    }
  }
  tr {
    height: 30px;
    line-height: 20px;
    border-bottom: #eee solid 0.5px;
    .list {
      div {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        background-color: rgb(137, 166, 165);
        color: #fff;
        text-align: center;
      }
      .first {
        display: inline-block;
        background-color: rgb(243, 193, 5);
        width: 25px;
        height: 25px;
        line-height: 25px;
        border-radius: 50%;
      }
      .second {
        display: inline-block;
        background-color: rgb(243, 121, 5);
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
      }
      .third {
        display: inline-block;
        background-color: rgb(50, 49, 48);
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
      }
    }
  }
  tr:nth-last-child(1) {
    border-bottom: none;
  }
}
</style>

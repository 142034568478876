<template>
  <div class="caseData bg_color">
    <h2>学案基础信息</h2>
    <p>
      学科：<span>{{ info.subjectName }}</span
      >&nbsp;&nbsp;&nbsp;科任老师：<span>{{ info.teacher.teacherName }}</span
      >&nbsp;&nbsp;&nbsp;代课老师：<span>{{
        Array.isArray(info.supply_teacher)
          ? "无"
          : info.supply_teacher.teacherName
      }}</span>
    </p>
    <div class="info">
      <div>
        <table>
          <!-- <tr>
            <td>学科：</td>
            <td>{{ info.subjectName }}</td>
          </tr> -->
          <tr>
            <td>学案名称：</td>
            <td>{{ info.title }}</td>
          </tr>
          <tr>
            <td>教材目录：</td>
            <td>
              {{ info.bookName ? info.bookName : "未定义教材" }}/{{
                info.tree_fullname ? info.tree_fullname : "未定义教材"
              }}
            </td>
          </tr>
          <tr>
            <td>学案起止时间：</td>
            <td>{{ info.startTime }}~{{ info.blockingTime }}</td>
          </tr>
          <tr>
            <td>学案发布时间：</td>
            <td>{{ info.startTime }}</td>
          </tr>
        </table>
      </div>
      <div>
        <div style="margin-bottom: 5px">学案倒计时：</div>

        <van-count-down :time="time">
          <template #default="timeData">
            <span class="block">{{ timeData.days }}</span>
            <span class="colon">天</span>
            <span class="block">{{ timeData.hours }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.minutes }}</span>
            <span class="colon">:</span>
            <span class="block">{{ timeData.seconds }}</span>
          </template>
        </van-count-down>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
let time = ref();
let props = defineProps(["info"]);

// 时间差
const TimeDifference = (start_time, end_time) => {
  let star = new Date().getTime();
  let end = new Date(end_time).getTime();
  time.value = end - star;
};
TimeDifference(props.info.startTime, props.info.blockingTime);
</script>

<style lang="less" scoped>
@import "@/common/style/bg_color.less";
.caseData {
  margin-bottom: 10px;
}
h2,
p {
  text-align: center;
}
p {
  font-size: 10px;
  span {
    color: #666;
  }
}
.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  table {
    tr {
      td:nth-child(1) {
        font-weight: 600;
      }
    }
  }
}
.colon {
  display: inline-block;
  margin: 0 4px;
  color: #ee0a24;
}
.block {
  display: inline-block;
  width: 22px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background-color: #ee0a24;
}

@media screen and (orientation: portrait) and (max-device-width: 420px) {
  .info {
    display: block;
    div:nth-child(1) {
      margin-bottom: 20px;
    }
  }
}
</style>

<template>
  <div id="caseDistr" ref="caseDistr"></div>
</template>

<script setup>
import { ref, onMounted, defineProps } from "vue";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

let props = defineProps(["title", "data"]);

let caseDistr = ref(null);

onMounted(() => {
  setTimeout(() => {
    var chartDom = caseDistr.value;
    var myChart = echarts.init(chartDom);

    let newArr = [];
    props.data.forEach((item) => {
      if (item.大于90 == 0) {
        for (let key in item) {
          newArr.push({ value: item[key], name: key + "%" });
        }
      } else {
        for (let key in item) {
          newArr.push({ value: item[key], name: key });
        }
      }
    });
    var option;
    option = {
      title: {
        text: props.title,
        left: "center",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        y: "bottom",
        x: "center",
      },
      series: [
        {
          type: "pie",
          radius: "50%",
          data: newArr,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    option && myChart.setOption(option);
  }, 1000);
});
</script>

<style lang="less" scoped>
#caseDistr {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
}
</style>

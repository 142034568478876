<template>
  <div class="source">
    <h3 style="text-align: center">学习资料</h3>
    <ul class="sList">
      <div class="sType">
        <van-image
          width="3vw"
          height="3vw"
          :src="require('@/assets/images/icon4.png')"
        />
        <div>学习资料</div>
      </div>
      <li
        v-for="(item, index) in data"
        @click="lookRes(item.ext, item.object_url)"
      >
        <div class="index">{{ index + 1 }}</div>
        <div class="sInfo">
          <div>
            <span>{{ item.ext }}</span>
            <span>{{ item.name }}</span>
          </div>
          <div>
            <span>学习状态：</span>
            <span>{{ item.isComplete ? "已完成" : "学习中" }}</span>
            <span>学习时长</span>
            <span>{{ (+item.learningDuration / 60).toFixed(2) }}分钟</span>
            <span class="look" @click.stop="lookTime(item.id)"
              >查看学生用时详情</span
            >
          </div>
        </div>
      </li>
      <div v-if="!data.length">
        <van-empty
          :image="require('@/assets/images/kong.png')"
          image-size="80"
          description="暂无数据"
        />
      </div>
    </ul>

    <van-popup v-model:show="showPop">
      <div class="timerData">
        <h3 style="text-align: center">学生学习时长</h3>
        <table class="timeData">
          <thead>
            <tr>
              <td>姓名</td>
              <td>用时(分钟)</td>
              <td>状态</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tableData">
              <td>{{ item.studentName }}</td>
              <td>{{ (+item.learingDuration / 60).toFixed(2) }}</td>
              <td>{{ item.compelteTime ? "学习中" : "未学习" }}</td>
            </tr>
            <tr v-if="!tableData.length">
              <td colspan="3">
                <van-empty
                  :image="require('@/assets/images/kong.png')"
                  image-size="80"
                  description="暂无数据"
                />
              </td>
            </tr>
          </tbody>
          <tfoot v-if="tableData.length">
            <tr>
              <td>总时长</td>
              <td>{{ (timeTotal / 60).toFixed(2) }}</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </van-popup>

    <van-popup v-model:show="showAudio">
      <audio autoplay v-if="showAudio" controls :src="prefix(audioUrl)"></audio>
    </van-popup>
    <van-popup v-model:show="showFile">
      <iframe
        :src="
          _ext == 'pdf' || _ext == 'txt'
            ? prefix(fileUrl)
            : prefixFile(prefix(fileUrl))
        "
        frameborder="0"
        class="if"
      ></iframe>
    </van-popup>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { useRouter } from "vue-router";
import { apiGetStudentLearningTime } from "@/service/teacher1.4.js";
import { prefix, prefixFile } from "@/common/js/utils.js";

const router = useRouter();

let props = defineProps(["data"]);

let showPop = ref(false);
let showAudio = ref(false);
let showFile = ref(false);

let tableData = ref([]);
let timeTotal = ref(0);
const lookTime = async (id) => {
  let res = await apiGetStudentLearningTime({ source_id: id });
  if (res) {
    showPop.value = true;
    tableData.value = res.data;
    timeTotal.value = res.data.reduce((sum, e) => {
      return sum + Number(e.learingDuration);
    }, 0);
  }
};

let audioUrl = ref("");
let fileUrl = ref("");
let _ext = ref("");
const lookRes = (type, url) => {
  if (type == "mp4") {
    router.push({ path: "/teacher/playVideo", query: { play: url } });
  }
  if (type == "mp3") {
    showAudio.value = true;
    audioUrl.value = url;
  }
  if (type !== "mp3" && type !== "mp4") {
    _ext.value = type;
    fileUrl.value = url;
    showFile.value = true;
  }
};
</script>

<style lang="less" scoped>
.sList {
  .sType {
    display: flex;
    align-items: center;
    font-size: 11px;
    div:nth-child(2) {
      margin-left: 10px;
    }
  }
  li {
    display: flex;
    align-items: center;
    font-size: 10px;
    padding-bottom: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-bottom: 1px #eee solid;
    .index {
      margin-right: 10px;
      color: #18a4e0;
      font-size: 12px;
    }
    .sInfo {
      div:nth-child(1) {
        span:nth-child(1) {
          background-color: #18a4e0;
          padding: 2px 3px;
          border-radius: 2px;
          color: #fff;
          margin-right: 5px;
        }
      }
      div:nth-child(2) {
        margin-top: 5px;
        span {
          margin-right: 10px;
        }
        .look {
          background-color: #18a4e0;
          color: #fff;
          padding: 2px;
          border-radius: 2px;
        }
      }
    }

    &:nth-last-child(1) {
      border-bottom: none;
    }
  }
}
.timerData {
  width: 80vw;
  max-height: 70vh;
  overflow-y: scroll;
  padding: 10px;
  .timeData {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    font-size: 10px;
    tr {
      height: 20px;
      line-height: 20px;
    }
    td {
      border: 0.5px solid #eee;
    }
    thead {
      background-color: #18a4e0;
      color: #fff;
    }
  }
}
:deep(.van-popup) {
  border-radius: 8px;
}

// audio {
//   position: fixed;
//   top: 40px;
//   right: 10px;
//   // background-color: rgb(41, 42, 43);
// }

.if {
  height: 70vh;
  width: 80vw;
}
</style>

export const color = [
  "rgb(238,118,79),rgb(236,48,12)",
  "rgb(93,236,127),rgb(17,203,81)",
  "rgb(93,236,127),rgb(17,203,81)",
  "rgb(238,118,79),rgb(236,48,12)",
  "rgb(235,172,109),rgb(223,111,18)",
  "rgb(235,172,109),rgb(223,111,18)",
  "rgb(235,127,96),rgb(222,58,18)",
  "rgb(235,127,96),rgb(222,58,18)",
];

export const printDataOverViewColor = [
  "rgb(236,189,103),rgb(244,133,21)",
  "rgb(7,192,65),rgb(90,228,111)",
  "rgb(196,147,229),rgb(175,47,211)",
  "rgb(236,189,103),rgb(244,133,21)",
  "rgb(7,192,65),rgb(90,228,111)",
  "rgb(196,147,229),rgb(175,47,211)",
  "rgb(236,189,103),rgb(244,133,21)",
  "rgb(7,192,65),rgb(90,228,111)",
  "rgb(196,147,229),rgb(175,47,211)",
  "rgb(236,189,103),rgb(244,133,21)",
  "rgb(7,192,65),rgb(90,228,111)",
  "rgb(196,147,229),rgb(175,47,211)",
];

<template>
  <div class="caseSis">
    <s-header :hasBack="true" />

    <n-bar :userType="'teacher'" :activeItemName="'index'" />
    <support />
    <div class="pageContent">
      <div class="in bg_main">
        <CaseDataVue v-if="showInfo" :info="caseInfo"></CaseDataVue>
        <van-tabs v-model:active="activeClass" @change="changeClassTab">
          <van-tab
            v-for="item in caseInfo.gradeClassArr"
            :title="item.name"
          ></van-tab>
        </van-tabs>

        <!-- 学案数据概览 -->
        <CaseOverview :dataOverview="dataOverview"></CaseOverview>

        <div class="tab">
          <div
            v-for="(item, index) in tabArr"
            :key="item"
            :class="tabIndex == index ? 'current' : ''"
            @click="changeTab(index)"
          >
            {{ item }}
          </div>
        </div>
        <div class="tab" v-show="tabIndex == 1">
          <div @click="active = 1" :class="active == 1 ? 'current' : ''">
            选择题({{ QuestionTypeTotal.chooseNum }})
          </div>
          <div @click="active = 2" :class="active == 2 ? 'current' : ''">
            判断题({{ QuestionTypeTotal.judgeNum }})
          </div>
          <div @click="active = 3" :class="active == 3 ? 'current' : ''">
            主观题({{ QuestionTypeTotal.subjective }})
          </div>
        </div>
        <div v-show="tabIndex == 0">
          <div>
            <CaseIndicatorsVue
              v-if="showBar"
              :data="barData"
            ></CaseIndicatorsVue>
          </div>
          <div class="rateAccuracy">
            <Distr
              :title="'正确率人数分布'"
              :data="correctRate"
              v-if="showCorrect"
            ></Distr>
          </div>
          <div class="takingTime">
            <Distr
              :title="'用时人数分布'"
              :data="avgTakeTime"
              v-if="showTakingTime"
            ></Distr>
          </div>
        </div>
        <div v-show="tabIndex == 1" class="bg_color">
          <SelectCase v-show="active == 1" :data="studentDoCase"></SelectCase>
          <JudgmentCase
            v-show="active == 2"
            :data="studentDoCase"
          ></JudgmentCase>
          <SubjectCase v-show="active == 3" :data="studentDoCase"></SubjectCase>
        </div>
        <div v-show="tabIndex == 2">
          <Ran :tableData="studentRanList" @changeRanType="changeRanType"></Ran>
        </div>
        <div v-show="tabIndex == 3" class="bg_color">
          <CaseSource :data="caseSourceList"></CaseSource>
          <!-- <van-empty
            v-if="!showSource && !caseSourceList.length"
            description="暂无数据"
            image-size="80"
            :image="('@/assets/images/kong.png')"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/**
 * 单个学案数据分析
 */
import { ref, onBeforeMount, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import CaseDataVue from "../components/CaseDataInfo.vue";
import CaseOverview from "../components/CaseOverview.vue";
import Ran from "@/views/teacher/components/ResultsRanking.vue";
import CaseIndicatorsVue from "../../chart/CaseIndicators.vue";
import CaseSource from "../components/CaseSource.vue";
import Distr from "../../chart/CaseDistr.vue";
import SelectCase from "../components/SelectCase.vue";
import JudgmentCase from "../components/JudgmentCase.vue";
import SubjectCase from "../components/SubjectCase.vue";
import { strToBase64 } from "@/common/js/utils.js";
import {
  apiGetOverviewOfAll,
  apiGetStudentRank,
  apiDistribution,
  apiGetCaseStatic,
  apiQuestionTypeTotal,
  apiGetCaseInfo,
  apiGetCaseSourceList,
} from "@/service/teacher1.4.js";

const route = useRoute();
const router = useRouter();

onBeforeMount(async () => {
  // 获取学案信息
  await getCaseInfo();
  // 学案数据概览
  getOverview();
  // 饼图数据
  getPieData();
  getAvgTakeTime();
  // 题目类型
  getQuestionTypeTotal();
  // 学生答题情况
  getDoCaseData();
});

let caseInfo = ref({});
let showInfo = ref(false);
const getCaseInfo = async () => {
  let res = await apiGetCaseInfo({ case_id: route.query.case_id });
  if (res) {
    caseInfo.value = res.data;
    showInfo.value = true;
  }
};

let activeClass = ref(0);
let tabArr = ref(["学案数据分布", "题目数据分析", "成绩排名", "教学资料"]);
let tabIndex = ref(0);

// 点击 tabArr tab的方法
const changeTab = async (index) => {
  tabIndex.value = index;
};

// 获取教学资源
let caseSourceList = ref([]);
let showSource = ref(false);
watch(
  () => tabIndex.value,
  async () => {
    if (tabIndex.value == 2) {
      getStudentRan();
    }
    if (tabIndex.value == 3) {
      let res = await apiGetCaseSourceList({
        main_id: route.query.case_id,
        class_id: caseInfo.value.gradeClassArr[activeClass.value].class_id,
      });
      if (res) {
        caseSourceList.value = [];
        for (let key in res.data) {
          caseSourceList.value.push(res.data[key]);
        }
        showSource.value = true;
      }
    }
  }
);

// 获取学案数据概览
let dataOverview = ref([]);
let barData = ref({});
let showBar = ref(false);
const getOverview = async (classId) => {
  let res = await apiGetOverviewOfAll({
    class_id: classId ? classId : caseInfo.value.classId,
    main_id: route.query.case_id,
  });
  if (res) {
    barData.value = res.data;
    dataOverview.value.push(
      {
        num: res.data.reviewstrNum,
        pNum: res.data.caseNum,
        title: "评语/学案数",
      },
      { num: res.data.qaNum, pNum: res.data.reviewPNum, title: "题目/资料数" },
      { num: res.data.issueNum, title: "题目疑问数" },
      {
        num: res.data.submitPNum,
        pNum: res.data.pendingSubmitPNum,
        title: "提交/待提交人数",
      },
      {
        num: res.data.reviewPNum,
        pNum: res.data.pendingReviewPNum,
        title: "批阅/待批阅人数",
      },
      {
        t: (+res.data.truePrecent).toFixed(2) + "%",
        f: (+res.data.wrongPrecent).toFixed(2) + "%",
        title1: "平均正确率",
        title2: "平均错误率",
      },
      {
        num: (+res.data.avgPTakeTime / 60).toFixed(2),
        title: "人均用时（分钟）",
      },
      {
        num: (+res.data.sumTakeTime / 60).toFixed(2),
        title: "总用时（分钟）",
      }
    );
    showBar.value = true;
  }
};

// 切换班级tab
const changeClassTab = async () => {
  // router.push({
  //   path: "/teacher/caseDataSis",
  //   query: {
  //     case_id: route.query.case_id,
  //     grade: route.query.grade,
  //     class_id: caseInfo.value.gradeClassArr[activeClass.value].class_id,
  //   },
  // });
  showBar.value = false;
  showCorrect.value = false;
  showTakingTime.value = false;
  dataOverview.value = [];
  getDoCaseData();
  await getOverview(caseInfo.value.gradeClassArr[activeClass.value].class_id);
  await getPieData();
  await getAvgTakeTime();
};

// 获取题目类型数量
let active = ref(1);
let QuestionTypeTotal = ref({});
const getQuestionTypeTotal = async () => {
  let res = await apiQuestionTypeTotal({ main_id: route.query.case_id });
  if (res) {
    QuestionTypeTotal.value = res.data[0];
  }
};

// 获取学生答题情况
let studentDoCase = ref([]);
const getDoCaseData = async () => {
  let res = await apiGetCaseStatic({
    main_id: route.query.case_id,
    class_id: caseInfo.value.gradeClassArr[activeClass.value].class_id,
    question_type: active.value,
  });
  if (res) {
    res.data.forEach((item) => {
      item.arr = [
        {
          title: "答错人数",
          num: +item.answer_analyze.errorPNum,
          type: "errorPNum",
        },
        {
          title: "答对人数",
          num: +item.answer_analyze.correctPNum,
          type: "correctPNum",
        },
        { title: "正确率", num: +item.answer_analyze.correctRate, type: "" },
        { title: "错误率", num: +item.answer_analyze.errorRate, type: "" },
        {
          title: "答题人数",
          num: +item.answer_analyze.dqPNum,
          type: "allPNum",
        },
        {
          title: "未答题人数",
          num: +item.answer_analyze.unDqPNum,
          type: "unDqPNum",
        },
        {
          title: "人均用时(秒)",
          num: +item.answer_analyze.avgTakeTime,
          type: "",
        },
        {
          title: "总用时(秒)",
          num: +item.answer_analyze.sumTakeTime,
          type: "",
        },
      ];
      item.sub = [
        {
          title: "答题人数",
          num: +item.answer_analyze.dqPNum,
          type: "allPNum",
        },
        {
          title: "未答题人数",
          num: +item.answer_analyze.unDqPNum,
          type: "unDqPNum",
        },
        {
          title: "人均用时(秒)",
          num: +item.answer_analyze.avgTakeTime,
          type: "",
        },
        {
          title: "总用时(秒)",
          num: +item.answer_analyze.sumTakeTime,
          type: "",
        },
      ];
    });
    studentDoCase.value = res.data;
  }
};
watch(
  () => active.value,
  () => {
    getDoCaseData();
  }
);

// 获取学生排行
let studentRanList = ref([]);
const getStudentRan = async (type) => {
  let res = await apiGetStudentRank({
    order_by: type ? type : "correctRate",
    class_id: caseInfo.value.gradeClassArr[activeClass.value].class_id,
    main_id: route.query.case_id,
  });
  if (res) {
    studentRanList.value = res.data;
  }
};
const changeRanType = async (val) => {
  studentRanList.value = [];
  await getStudentRan(val);
};

// 获取平均正确率数据
let showCorrect = ref(false);
let correctRate = ref([]);
const getPieData = async () => {
  let params = strToBase64(
    JSON.stringify([
      "correctRate >= 50,小于等于50",
      "correctRate > 50 && correctRate <= 60,大于50小于等于60",
      "correctRate > 60 && correctRate <= 80,大于60小于等于80",
      "correctRate > 80 && correctRate <= 90,大于80小于等于90",
      "correctRate > 90,大于90",
    ])
  );

  let res = await apiDistribution({
    selects: params,
    class_id: caseInfo.value.gradeClassArr[activeClass.value].class_id,
  });
  if (res) {
    correctRate.value = res.data;
    showCorrect.value = true;
  }
};
// 获取平均用时饼图数据
let showTakingTime = ref(false);
let avgTakeTime = ref([]);
const getAvgTakeTime = async () => {
  let params = strToBase64(
    JSON.stringify([
      "avgTakeTime  >= 10,小于等于10min",
      "avgTakeTime  > 10 && avgTakeTime  <= 30,大于10小于等于30min",
      "avgTakeTime  > 30 && avgTakeTime  <= 60,大于30小于等于60min",
      "avgTakeTime  > 90 && avgTakeTime  <= 120,大于90小于等于120min",
      "avgTakeTime  > 120,大于120min",
    ])
  );

  let res = await apiDistribution({
    selects: params,
    class_id: caseInfo.value.gradeClassArr[activeClass.value].class_id,
  });
  if (res) {
    avgTakeTime.value = res.data;
    showTakingTime.value = true;
  }
};
</script>

<style lang="less" scoped>
@import "@/common/style/pageContent.less";
@import "@/common/style/adapting.less";
@import "@/common/style/bg_color.less";

.tab {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  div:nth-child(1) {
    border-radius: 5px 0 0 5px;
  }
  div:nth-last-child(1) {
    border-radius: 0 5px 5px 0;
  }
  div {
    margin: 0;
    border: 1px solid #18a4e0;
    width: 50%;
    text-align: center;
  }
  .current {
    background-color: rgb(101, 130, 235);
    border: none;
    color: #fff;
  }
}

.rateAccuracy,
.takingTime {
  width: 100%;
  height: 350px;
  margin-top: 10px;
}

@media screen and (orientation: portrait) and (max-device-width: 420px) {
  .in {
    padding-bottom: 80px;
  }
}

.bg_main {
  background-color: rgb(247, 244, 244);
  padding: 0;
}
:deep(.van-tabs__wrap) {
  border-radius: 6px;
}
</style>

<template>
  <div id="caseIndicators"></div>
</template>

<script setup>
import { ref, onMounted, defineProps } from "vue";
import * as echarts from "echarts/core";
import {
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer,
  TitleComponent,
]);

let props = defineProps(["data"]);
onMounted(() => {
  setTimeout(() => {
    let newArr = [
      [
        "",
        "提交人数",
        "待提人数",
        "批阅人数",
        "待批人数",
        "题目数",
        "疑问题目数",
        "评价人数",
        "人均用时(分钟)",
        "总用时(分钟)",
      ],
      [
        "",
        +props.data.submitPNum,
        +props.data.pendingSubmitPNum,
        +props.data.reviewPNum,
        +props.data.pendingReviewPNum,
        +props.data.qaNum,
        +props.data.issueNum,
        +props.data.reviewstrNum,
        (+props.data.avgPTakeTime / 60).toFixed(2),
        (+props.data.sumTakeTime / 60).toFixed(2),
      ],
    ];
    var app = {};
    var chartDom = document.getElementById("caseIndicators");
    var myChart = echarts.init(chartDom);
    var option;

    option = {
      title: {
        text: "学案指标数据分布",
        left: "center",
      },
      legend: { y: "bottom", x: "center" },
      tooltip: {},
      dataset: {
        source: newArr,
      },
      xAxis: { type: "category" },
      yAxis: {},
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: [
        { type: "bar" },
        { type: "bar" },
        { type: "bar" },
        { type: "bar" },
        { type: "bar" },
        { type: "bar" },
        { type: "bar" },
        { type: "bar" },
        { type: "bar" },
      ],
    };

    option && myChart.setOption(option);
  }, 500);
});
</script>

<style lang="less" scoped>
#caseIndicators {
  width: 100%;
  height: 350px;
  // margin-top: 20px;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 10px;
}
</style>

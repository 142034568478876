<template>
  <div class="subject">
    <ul>
      <li v-for="item in data">
        <div class="caseQuestionIndex">
          <div>
            <span class="index">1</span>
            <span @click="reviewQuestion(item.question, item.questionWay)"
              >预览题目</span
            >
            <span>已批阅人数&nbsp; {{ item.reviewedNum }}</span>
            <span>待批阅人数&nbsp; {{ item.notReviewNum }}</span>
          </div>
          <!-- <div>查看答案分布></div> -->
        </div>
        <ul class="doDate">
          <li
            v-for="(subitem, subindex) in item.sub"
            :style="{
              backgroundImage: `linear-gradient(90deg,${color[subindex + 4]})`,
            }"
            @click="lookData(item.qaIds, subitem.title, subitem.type)"
          >
            <div class="text">
              <p>{{ subitem.title }}</p>
              <p>{{ subitem.num }}</p>
            </div>
          </li>
        </ul>
        <div class="answer">
          <div>答案</div>
          <div v-if="item.answerType == 1">{{ item.answer }}</div>
          <div v-if="item.answerType == 2">
            <van-image
              width="10vw"
              height="10vw"
              :src="prefix(item.answer)"
              @click="openPreview(prefix(item.answer))"
            />
          </div>
        </div>
      </li>
      <li v-if="!data.length">
        <van-empty
          description="暂无数据"
          image-size="80"
          :image="require('@/assets/images/kong.png')"
        />
      </li>
    </ul>

    <van-popup v-model:show="showLookData">
      <div class="tableBox">
        <table class="dataShow">
          <thead>
            <tr>
              <td>姓名</td>
              <td>答案</td>
              <td>批改</td>
              <td>用时（秒）</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in tableData" :key="index">
              <td>{{ item.studentName }}</td>
              <td v-if="item.answer">
                <van-image
                  width="5vw"
                  height="5vw"
                  :src="prefix(item.answer)"
                  @click="openPreview(prefix(item.answer))"
                />
              </td>
              <td>
                <van-image
                  v-if="item.fileUrl"
                  width="5vw"
                  height="5vw"
                  :src="prefix(item.fileUrl)"
                  @click="openPreview(prefix(item.fileUrl))"
                />
                <span v-if="!item.fileUrl">无</span>
              </td>
              <td v-if="!item.answer">0</td>
              <td>{{ item.takeTime }}</td>
            </tr>
            <tr v-if="!tableData.length">
              <td colspan="3">
                <van-empty
                  description="暂无数据"
                  image-size="80"
                  :image="require('@/assets/images/kong.png')"
                />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3">总用时</td>
              <td>{{ sumTime }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </van-popup>

    <van-popup v-model:show="showQuestion">
      <div v-if="questionWay == 1" class="question" v-html="caseQuestion"></div>
      <div class="question">
        <van-image
          v-if="questionWay == 2"
          width="80vw"
          height="80vh"
          :src="prefix(caseQuestion)"
        />
      </div>
      <iframe
        v-if="questionWay == 3"
        :src="prefixFile(prefix(caseQuestion))"
        frameborder="0"
        width="80vw"
        height="80vh"
      ></iframe>
    </van-popup>

    <van-image-preview
      v-model:show="showPreview"
      :images="previewImgs"
      :loop="false"
      :closeable="true"
      :maxZoom="2"
    >
    </van-image-preview>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import { apiGetAnswerStudentList } from "@/service/teacher1.4.js";
import { prefix, prefixFile } from "@/common/js/utils.js";
import { color } from "./gradientcolor";

let props = defineProps(["data"]);
let showLookData = ref(false);
let tableData = ref([]);
let sumTime = ref(0);
const lookData = async (id, type, param) => {
  if (
    type == "答错人数" ||
    type == "答对人数" ||
    type == "答题人数" ||
    type == "未答题人数" ||
    type == "总用时(秒)"
  ) {
    let res = await apiGetAnswerStudentList({ qa_id: id, type: param });
    showLookData.value = true;
    if (res) {
      tableData.value = res.data;
      sumTime.value = res.data.reduce((sum, e) => {
        return sum + e.takeTime;
      }, 0);
    }
  }
};

let showPreview = ref(false);
let previewImgs = ref([]);
const openPreview = (imgSrc) => {
  showPreview.value = true;
  previewImgs.value = [imgSrc];
};

// 预览题目
let showQuestion = ref(false);
let caseQuestion = ref("");
let questionWay = ref(1);
const reviewQuestion = (val, type) => {
  showQuestion.value = true;
  questionWay.value = type;
  caseQuestion.value = val;
};
</script>

<style lang="less" scoped>
.answer {
  div:nth-child(1) {
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 10px;
  }
}
.subject {
  font-size: 10px;
}
.caseQuestionIndex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div:nth-child(1) {
    span {
      display: inline-block;
      border: 1px solid #18a4e0;
      border-radius: 5px;
      margin-right: 15px;
      height: 20px;
      line-height: 20px;
      padding: 0 10px;
      &:nth-child(2) {
        background-color: #18a4e0;
        color: #fff;
      }
      &:nth-child(4) {
        border: rgb(41, 214, 96) 1px solid;
      }
    }
    .index {
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 50%;
      padding: 0;
      color: #18a4e0;
    }
  }
  div:nth-child(2) {
    height: 20px;
    line-height: 20px;
    background-color: #18a4e0;
    color: #fff;
    padding: 0 10px;
    border-radius: 5px;
  }
}
.doDate {
  height: 80px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto;
  grid-gap: 10px;
  margin: 10px 0 20px;
  // padding: 10px;
  /* grid-auto-flow: row dense; */
  .text {
    text-align: center;
    font-size: 11px;
    color: #fff;
    font-weight: 600;
  }
}
.doDate > li {
  // text-align: center;
  border-radius: 5px;
}
.tableBox {
  padding: 10px;
  max-height: 70vh;
  overflow-y: scroll;
  .dataShow {
    width: 60vw;
    font-size: 10px;
    text-align: center;
    border-collapse: collapse;
    thead {
      background-color: #18a4e0;
    }
    tfoot {
      background-color: rgb(213, 230, 238);
    }
    tr {
      height: 20px;
      line-height: 20px;
    }
    td {
      border: 0.5px solid #eee;
    }
  }
}
:deep {
  .van-popup {
    border-radius: 10px;
  }
}
:deep(.van-image-preview__close-icon--top-right) {
  top: 35px;
}

:deep(.van-image-preview__image--vertical) {
  height: 89% !important;
}
.question {
  padding: 10px;
  width: 80vw;
  height: 80vh;
}
</style>

let colorArr = [
  "rgb(76, 192, 237),rgb(108, 138, 216)",
  "rgb(159,234,178),rgb(29,164,62)",
  "rgb(198,158,232),rgb(171,32,208)",
  "rgb(235,205,20),rgb(238,210,71)",
  "rgb(52,13,216),rgb(102,92,229)",
  "rgb(102,92,229),rgb(52,13,216)",
  "rgb(197,200,9),rgb(211,233,114)",
  "rgb(225,103,8),rgb(230,94,60)",
  "rgb(197,58,8),rgb(238,102,45)",
];

export default colorArr;
